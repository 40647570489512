
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import StackedForm from '@/mixins/StackedForm';
import Notification from '@/mixins/Notification';
import { EditVenue as EditVenueMixin } from '@/mixins/EditVenue';
import VWrapper from '@/components/shared/VWrapper.vue';
import DeliveryHourForm from '@/components/venue/form/DeliveryHourForm.vue';
import OpeningHourForm from '@/components/venue/form/OpeningHourForm.vue';
import SimpleGeneralForm from '@/components/venue/form/SimpleGeneralForm.vue';
import PreorderForm from '@/components/venue/form/PreorderForm.vue';
import EditSimpleVenueLoader from '@/components/venue/EditSimpleVenueLoader.vue';
import DocumentForm from '@/components/venue/form/DocumentForm.vue';
import OrderOffsetForm from '@/components/venue/form/OrderOffsetForm.vue';
import { CustomerGroup } from '@/enums/CustomerGroup';
import { Country } from '@/enums/Country';
import { ValidationObserver } from 'vee-validate';

@Component({
  components: {
    OrderOffsetForm,
    EditSimpleVenueLoader,
    PreorderForm,
    SimpleGeneralForm,
    OpeningHourForm,
    DeliveryHourForm,
    DocumentForm,
    VWrapper,
  },
})
export default class EditSimpleVenue extends mixins(StackedForm, Notification, EditVenueMixin) {
  public $refs!: {
    general: InstanceType<typeof SimpleGeneralForm> & { getData: () => any };
    preorder: InstanceType<typeof PreorderForm> & { getData: () => any };
    openingHours: InstanceType<typeof OpeningHourForm> & { getData: () => any };
    deliveryHours: InstanceType<typeof DeliveryHourForm> & { getData: () => any };
    documents: InstanceType<typeof DocumentForm> & { getData: () => any };
    orderOffsets: InstanceType<typeof OrderOffsetForm> & { getData: () => any };
    observer: InstanceType<typeof ValidationObserver>;
  };

  public ppAuthDialog: boolean = false;
  public ppAuthModel: { ppMerchantId: string; ppEmail: string } = {
    ppMerchantId: '',
    ppEmail: '',
  };

  get allowedCountries() {
    if (this.venue && this.venue.customerGroup === CustomerGroup.Subway) {
      return [Country.DE, Country.AU];
    } else if (this.venue && this.venue.customerGroup === CustomerGroup.SubwayFr) {
      return [Country.FR];
    } else if (this.venue && this.venue.customerGroup === CustomerGroup.SubwayLu) {
      return [Country.LU];
    }

    return [Country.DE, Country.AU];
  }

  get shouldDisableRegister() {
    return this.venue && this.venue.customerGroup === CustomerGroup.SubwayFr;
  }

  get shouldDisablePreorderInside() {
    if (
      this.venue &&
      (this.venue.customerGroup === CustomerGroup.Subway ||
        this.venue.customerGroup === CustomerGroup.SubwayFr ||
        this.venue.customerGroup === CustomerGroup.SubwayLu)
    ) {
      return true;
    }
    return (
      this.$getLinkedCustomerGroup() === CustomerGroup.Subway ||
      this.$getLinkedCustomerGroup() === CustomerGroup.SubwayFr ||
      this.$getLinkedCustomerGroup() === CustomerGroup.SubwayLu
    );
  }

  get allowedLegalTypes() {
    if (
      this.venue &&
      (this.venue.customerGroup === CustomerGroup.SubwayFr || this.venue.customerGroup === CustomerGroup.SubwayLu)
    ) {
      return ['sa', 'sarl', 'eurl', 'sas', 'autres'];
    }

    return null;
  }

  get shouldDisableDeliveryPostalCode() {
    const allowedCustomerGroups: CustomerGroup[] = [
      CustomerGroup.Subway,
      CustomerGroup.Kochloeffel,
      CustomerGroup.Immergruen,
    ];
    if (this.venue && !allowedCustomerGroups.includes(this.venue.customerGroup)) {
      return true;
    }
    return !allowedCustomerGroups.includes(this.$getLinkedCustomerGroup());
  }

  get shouldDisablePreorderDelivery() {
    if (
      this.venue &&
      (this.venue.customerGroup === CustomerGroup.SubwayFr || this.venue.customerGroup === CustomerGroup.SubwayLu)
    ) {
      return true;
    }

    return (
      this.$getLinkedCustomerGroup() === CustomerGroup.SubwayFr ||
      this.$getLinkedCustomerGroup() === CustomerGroup.SubwayLu
    );
  }

  public async connectPayPalAccount() {
    this.ppAuthModel.ppMerchantId = this.venue?.ppMerchantId ?? '';
    this.ppAuthModel.ppEmail = this.venue?.ppEmail ?? '';
    this.ppAuthDialog = true;
  }

  public async connectByCustomCredentials() {
    if (!(await this.validatePayPalModel())) return;

    let data: { id: string; ppEmail: string; ppMerchantId: string } = { ...this.ppAuthModel, id: this.active._id };
    try {
      this.$startLoading('ppConnect');
      await this.update(data);
      await this.authorizePayPal();
    } catch (e) {
      console.log(e);
    } finally {
      this.ppAuthDialog = false;
      this.$stopLoading('ppConnect');
    }
  }

  public validatePayPalModel(): Promise<boolean> {
    return this.$refs.observer.validate();
  }

  public ppAuthClose() {
    this.ppAuthDialog = false;
  }

  public async save() {
    Promise.all(await this.validateWithKeys()).then(async (res: any) => {
      this.$startLoading('venue.save');

      const isValidWithKey: any = this.isValidWithKey(res);

      if (isValidWithKey.result) {
        const mergedData: any = this.getMergedData();
        const documents: { terms: string | Blob; imprint: string | Blob; privacy: string | Blob } = {
          ...mergedData.legal,
        };
        const data: any = this.sanitize(mergedData);

        // delete data.legal;
        data.id = this.active._id;
        await this.update(data);

        const formData: FormData = new FormData();
        if (documents.terms && documents.terms instanceof File) {
          formData.set('file', documents.terms);
          await this.uploadTermsAndConditions({ id: this.active._id, file: formData });
        }
        if (documents.imprint && documents.imprint instanceof File) {
          formData.set('file', documents.imprint);
          await this.uploadImprint({ id: this.active._id, file: formData });
        }
        if (documents.privacy && documents.privacy instanceof File) {
          formData.set('file', documents.privacy);
          await this.uploadPrivacy({ id: this.active._id, file: formData });
        }

        this.$router.push({ name: 'venue.index', query: { ...this.$route.query } });
      } else {
        const errorTab: string = this.$t(`venue.tabs.${isValidWithKey.refKey}`).toString();
        const errorMsg: string = `${this.$t('notification.form')} (${errorTab})`;
        this.notifyError(errorMsg);
      }

      this.$stopLoading('venue.save');
    });
  }

  public getMergedData() {
    const data: object = {
      ...this.$refs.general.getData(),
      ...this.$refs.preorder.getData(),
      ...this.$refs.openingHours.getData(),
      ...this.$refs.documents.getData(),
      ...this.$refs.orderOffsets.getData(),
    };
    if (this.$refs.deliveryHours) {
      return {
        ...data,
        ...this.$refs.deliveryHours.getData(),
      };
    }

    return data;
  }
}
