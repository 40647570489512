
import { Component, Emit, Prop } from 'vue-property-decorator';
import VFormBuilder from '../../shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
import StackedForm from '@/mixins/StackedForm';
import { mixins } from 'vue-class-component';
import { Localization } from '@/interfaces/models/Localization';
import { Address, HereApiService, Location } from '@/api/http/HereApiService';
import Countries from '@/mixins/Countries';
import { SelectItem } from '@/interfaces/components/SelectItem';
import LegalForm from '@/mixins/LegalForm';
import { Venue } from '@/interfaces/models/Venue';
import { Country } from '@/enums/Country';
import cookie from 'js-cookie';
import { SubwayRoleType } from '@/enums/SubwayRoleType';
import { generateQRCode, toDownloadImage } from '@/util/helper';

@Component({
  components: { VFormBuilder },
})
export default class SimpleGeneralForm extends mixins(StackedForm, Countries, LegalForm) {
  @Prop({ type: Array, required: false, default: () => Object.values(Country) }) public allowedCountries!: string[];
  @Prop({ type: Array, default: null }) public allowedLegalTypes!: string[] | null;

  public country: Country | null = null;
  public useVatNumberGermany: boolean = false;
  public useManualGpsInput: boolean = false;
  public qrCodeOfStore: string = '';

  public $refs!: {
    baseForm: InstanceType<typeof VFormBuilder> & { form: any };
    contactForm: InstanceType<typeof VFormBuilder> & { form: any };
    invoiceReceiverForm: InstanceType<typeof VFormBuilder> & { form: any };
    addressForm: InstanceType<typeof VFormBuilder> & { form: any };
    miscellaneousForm: InstanceType<typeof VFormBuilder> & { form: any };
  };

  public async mounted() {
    // @ts-ignore
    if (!this.initialValues.vatNumber && this.initialValues.vatNumberGermany) {
      this.useVatNumberGermany = true;
    }

    // @ts-ignore
    if (this.initialValues.country) {
      // @ts-ignore
      this.country = this.initialValues.country;
    }
    // @ts-ignore
    if (this.initialValues?.readableId && this.isSubwayRole) {
      // @ts-ignore
      this.qrCodeOfStore = await generateQRCode(this.subwayStoreUrl);
    }
  }

  @Emit('change:base')
  public onBasicDataChange(val: string, key: string) {
    const invoiceReceiver: Partial<Venue> =
      // @ts-ignore
      this.initialValues && this.initialValues.invoiceReceiver ? { ...this.initialValues.invoiceReceiver } : {};
    const basicData: Partial<Venue> = { ...this.initialValues, invoiceReceiver } as Partial<Venue>;
    if (!key.includes('invoiceReceiver')) {
      // @ts-ignore
      basicData[key] = val;
    } else {
      const parts: string[] = key.split('.');
      // @ts-ignore
      basicData.invoiceReceiver[parts[1]] = val;
    }

    return basicData;
  }

  @Emit('notifyError')
  public notifyError(msg: string): string {
    return msg;
  }

  get initialAddressValues() {
    const initialValues: object = { ...this.initialValues };

    if (
      // @ts-ignore
      initialValues.location &&
      // @ts-ignore
      initialValues.location.coordinates &&
      // @ts-ignore
      initialValues.location.coordinates.length > 1
    ) {
      // @ts-ignore
      initialValues.longitude = this.initialValues.location.coordinates[0];
      // @ts-ignore
      initialValues.latitude = this.initialValues.location.coordinates[1];
    }

    return initialValues;
  }

  get baseItems() {
    return [
      { name: 'name', type: InputType.Text, label: 'venue.form.name', rules: 'required' },
      { name: '_id', type: InputType.Text, label: 'venue.form.restaurantToken', readonly: true },
      { name: 'isServiceActivated', type: InputType.Checkbox, label: 'venue.form.isServiceActivated' },
      {
        name: 'legalForm',
        type: InputType.Select,
        label: 'venue.form.legalForm',
        rules: 'required',
        items: this.legalFormItems,
      },
    ];
  }

  get contactItems() {
    return [
      {
        name: 'email',
        type: InputType.Text,
        label: 'venue.form.email',
        rules: 'required|email',
        change: (val: string) => this.onBasicDataChange(val, 'email'),
      },
      { name: 'orderEmail', type: InputType.Text, label: 'venue.form.orderEmail', rules: 'email' },
      { name: 'phoneNumber', type: InputType.Text, label: 'venue.form.phone' },
    ];
  }

  get miscellaneousItems() {
    return [
      {
        name: 'vatNumber',
        type: InputType.Text,
        label: 'venue.form.vatNumber',
        rules: 'required|alpha_num|uppercase|min:5|max:20|vat_number:venue',
        change: (val: string) => this.onBasicDataChange(val, 'vatNumber'),
        visible: !this.useVatNumberGermany,
      },
      {
        name: 'vatNumberGermany',
        type: InputType.Text,
        label: 'venue.form.vatNumberGermany',
        rules: 'required|min:5|max:20|vat_number_germany:venue',
        change: (val: string) => this.onBasicDataChange(val, 'vatNumberGermany'),
        visible: this.useVatNumberGermany,
      },
    ];
  }

  get invoiceReceiverItems() {
    return [
      {
        name: 'email',
        type: InputType.Text,
        label: 'venue.form.invoiceReceiver.email',
        rules: 'email',
        change: (val: string) => this.onBasicDataChange(val, 'invoiceReceiver.email'),
      },
      {
        name: 'fullName',
        type: InputType.Text,
        label: 'venue.form.fullName',
        rules: 'required',
        change: (val: string) => this.onBasicDataChange(val, 'invoiceReceiver.fullName'),
      },
      {
        name: 'street',
        type: InputType.Text,
        label: 'venue.form.street',
        rules: 'required',
        change: (val: string) => this.onBasicDataChange(val, 'invoiceReceiver.street'),
      },
      {
        name: 'number',
        type: InputType.Text,
        label: 'venue.form.number',
        rules: 'required',
        change: (val: string) => this.onBasicDataChange(val, 'invoiceReceiver.number'),
      },
      {
        name: 'postalCode',
        type: InputType.Text,
        label: 'venue.form.postalCode',
        rules: 'required',
        change: (val: string) => this.onBasicDataChange(val, 'invoiceReceiver.postalCode'),
      },
      {
        name: 'city',
        type: InputType.Text,
        label: 'venue.form.city',
        rules: 'required',
        change: (val: string) => this.onBasicDataChange(val, 'invoiceReceiver.city'),
      },
    ];
  }

  get addressItems() {
    return [
      {
        name: 'country',
        type: InputType.Select,
        label: 'venue.form.country',
        rules: 'required',
        items: this.countries.filter((item: SelectItem) => this.allowedCountries.includes(item.value as string)),
        change: this.changedCountry,
      },
      {
        name: 'street',
        type: InputType.Text,
        label: 'venue.form.street',
        rules: 'required',
        change: this.changedAddress,
      },
      {
        name: 'number',
        type: InputType.Text,
        label: 'venue.form.number',
        rules: 'required',
        change: this.changedAddress,
      },
      {
        name: 'city',
        type: InputType.Language,
        label: 'venue.form.city',
        rules: 'required',
        change: this.changedAddress,
      },
      {
        name: 'postalCode',
        type: InputType.Text,
        label: 'venue.form.postalCode',
        rules: 'required',
        change: this.changedAddress,
      },
      {
        name: 'latitude',
        type: InputType.Text,
        label: 'venue.form.latitude',
        disabled: !this.useManualGpsInput,
      },
      {
        name: 'longitude',
        type: InputType.Text,
        label: 'venue.form.longitude',
        disabled: !this.useManualGpsInput,
      },
    ];
  }

  get legalFormItems() {
    if (this.allowedLegalTypes) {
      return this.legalForms.filter((i: SelectItem) => this.allowedLegalTypes!.includes(i.value as string));
    }

    return this.legalForms;
  }

  get showVatNumberGermanyOption() {
    // @ts-ignore
    return this.country && this.country === Country.DE;
  }

  public get isSubwayRole() {
    return Object.values(SubwayRoleType).includes(cookie.get('role') as SubwayRoleType);
  }

  public get subwayStoreUrl() {
    // @ts-ignore
    return `${process.env.VUE_APP_SUBWAY_URL}${this.initialValues.readableId}`;
  }

  public downloadQRCodeOfStore() {
    toDownloadImage(this.qrCodeOfStore, 'qrcode.png');
  }

  public async changedCountry(country: Country) {
    this.country = country;
    if (this.useVatNumberGermany && this.country !== Country.DE) {
      this.useVatNumberGermany = false;
    }
  }

  public async changedAddress(
    _: any,
    form: {
      street: string;
      number: string;
      postalCode: string;
      city: Localization;
      longitude: number;
      latitude: number;
      location?: { coordinates: number[]; type: string };
    },
  ) {
    if (form.street !== '' && form.number !== '' && form.postalCode !== '' && form.city.de !== '') {
      this.$startLoading('venue.location');
      const location: Location | null = await HereApiService.getGeoCoordinates(form as unknown as Address);
      if (location) {
        if (!form.location) {
          form.location = { coordinates: [0, 0], type: 'Point' };
        }
        form.location.type = 'Point';
        form.location.coordinates[0] = location.lng;
        form.location.coordinates[1] = location.lat;
        this.$refs.addressForm.setData({ ...form, latitude: location.lat, longitude: location.lng });
      } else {
        this.notifyError('notification.gpsError');
      }
      this.$stopLoading('venue.location');
    }
  }

  public toggleVatNumberGermany() {
    this.useVatNumberGermany = !this.useVatNumberGermany;
  }

  public getBaseFormData() {
    const baseFormData: any = { ...this.$refs.baseForm.form };

    if (baseFormData.hasOwnProperty('_id')) {
      delete baseFormData._id;
    }

    return baseFormData;
  }

  public getData() {
    if (this.useVatNumberGermany) {
      this.$refs.miscellaneousForm.form.vatNumber = '';
    } else {
      this.$refs.miscellaneousForm.form.vatNumberGermany = null;
    }

    return {
      ...this.getBaseFormData(),
      ...this.$refs.contactForm.form,
      ...this.$refs.addressForm.form,
      ...this.$refs.miscellaneousForm.form,
      invoiceReceiver: {
        ...this.$refs.invoiceReceiverForm.form,
      },
    };
  }
}
